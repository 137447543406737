@tailwind base;
@tailwind components;
@tailwind utilities;

.pagination {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0;
  }
  
  .page-item {
    margin: 0 5px;
  }
  
  .page-link {
    padding: 8px 16px;
    text-decoration: none;
    color: #374151;
    border: 1px solid #374151;
    border-radius: 4px;
  }
  
  .page-link:hover {
    background-color: #374151;
    color: white;
  }
  
  .active .page-link {
    background-color: #374151;
    color: white;
    border-color: #374151;
    border-width: 2px;
  }
  
  .page-item.disabled .page-link {
    color: #6c757db8;
    pointer-events: none;
    cursor: default;
  }
  .pagination-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    margin-top: 100px;
    background-color: #FFAFBC /* Optional: To ensure readability */
    
  }
